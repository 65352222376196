@import '@gs-ux-uitoolkit-react/core/style/main';
@import '@gs-ux-uitoolkit-react/person/style/main';
@import '@gs-ux-uitoolkit-react/header/style/main';
@import '@gs-ux-uitoolkit-react/datagrid/style/main';
@import '@gs-ux-uitoolkit-react/datatoolbar/style/main';
@import '@gs-ux-uitoolkit-react/toast/style/main';

html {
   height: 100%;

   body {
      height: 100%;

      > div#root {
         height: 100%;
      }
   }
}

.popover-inner {
   @extend .gs-uitk-shadow-100;

   background-color: white;

   .popover-body {
      color: black;
   }
}
