@import '@gs-ux-uitoolkit-common/core/style/vars';

.linkButton {
   padding: 0;
   background: unset;
   border: unset;
   color: $gs-uitk-blue-050;

   &:focus {
      outline: unset;
   }
}

.actionsContainer {
   width: 100%;
   text-align: center;
}

.actionButtonsContainer {
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   padding-top: $gs-uitk-space-1;

   > button {
      margin-left: $gs-uitk-space-1;
   }
}
