@import '@gs-ux-uitoolkit-common/core/style/vars';

$totalHeight: 300px;
$headerHeight: 32px;
$contentHeight: $totalHeight - $headerHeight;

.rootContainer {
   position: fixed;
   right: 30px;
   bottom: -$totalHeight;

   width: 400px;
   height: $totalHeight;
   background-color: white;

   transition: transform 0.15s;

   z-index: 200;

   &Closed {
      display: none;
   }

   &Minimised {
      bottom: -$contentHeight;
   }

   &Maximised {
      transform: translateY(-$totalHeight);
   }
}

.headerContainer {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   height: $headerHeight;

   background-color: $gs-uitk-gray-080;
   color: white;

   padding-left: 12px;
   padding-right: 8px;

   .headerButton {
      padding: 0px 4px;
      border: unset;
      outline: unset;
      background-color: unset;

      color: white;

      &:hover,
      &:active,
      &:focus {
         border: unset !important;
         outline: unset !important;
         background-color: unset !important;
         color: white !important;
      }

      &:hover {
         color: $gs-uitk-gray-030 !important;
      }

      &::before {
         border: unset !important;
      }
   }
}

.contentContainer {
   overflow-y: auto;
   height: $contentHeight;
}

.minimiseIcon {
   transition: transform 0.15s;
}

.minimiseIconOpen {
   transform: rotate(180deg);
}
