@import '@gs-ux-uitoolkit-common/core/style/vars';

.directoryListRootContainer {
   height: 100%;

   display: flex;
   flex-direction: column;
   background-color: white;

   padding-top: $gs-uitk-space-2;
   padding-bottom: $gs-uitk-space-2;
}

.headerContainer {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
}

.headerButtonsContainer {
   button {
      margin-left: $gs-uitk-space-1;
   }
}

.dataGridContainer {
   width: 100%;
   height: 100%;

   padding-top: 24px;
}

.breadcrumbItem {
   &::before {
      margin-top: 2px !important;
   }
}

.breadcrumbLink {
   padding: 0;
   background: unset;
   border: unset;
   color: $gs-uitk-blue-050;

   &:focus {
      outline: unset;
   }

   &:disabled {
      color: $gs-uitk-blue-030;
   }
}

.smallIcon {
   font-size: 10px !important;
}
