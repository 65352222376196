@import '@gs-ux-uitoolkit-common/core/style/vars';

.loginRootContainer {
   height: 100%;

   display: flex;
   flex-direction: column;

   padding-top: 200px;
}

.contentPanel {
   background-color: white;

   padding-top: $gs-uitk-space-2;
   padding-bottom: $gs-uitk-space-4;

   h4 {
      margin-top: 0;
   }
}

.errorFeedbackMessage {
   padding-left: $gs-uitk-space-1;
   color: $gs-uitk-red-060;
}

.successFeedbackMessage {
   padding-left: $gs-uitk-space-1;
   color: $gs-uitk-green-060;
}
