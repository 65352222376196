@import '@gs-ux-uitoolkit-common/core/style/vars';

@keyframes rotation {
   from {
      transform: rotate(0deg);
   }
   to {
      transform: rotate(359deg);
   }
}

.rowContainer {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   width: 100%;

   .progressContainer {
      flex: 1;
      padding-right: $gs-uitk-space-1;
   }

   .rowActionsContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
   }
}

.confirmPopupActionsContainer {
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   padding-top: $gs-uitk-space-1;

   > button {
      margin-left: $gs-uitk-space-1;
   }
}

.icon {
   // margin-right applied to move the icon to be in line
   // with potential close button in the row above/below
   margin-right: 10px;
}

.checkIcon {
   color: $gs-uitk-green-040;
}

.loadingIcon {
   color: $gs-uitk-blue-050;
   animation: rotation 1s infinite linear;
}

.closeIcon {
   color: $gs-uitk-red-060;
}
