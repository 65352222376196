@import '@gs-ux-uitoolkit-common/core/style/vars';

.rootContainer {
   height: 100%;

   display: flex;
   flex-direction: column;

   padding-top: 200px;
}

.contentPanel {
   background-color: white;

   padding-top: $gs-uitk-space-2;
   padding-bottom: $gs-uitk-space-4;

   h4 {
      margin-top: 0;
   }
}

.passwordRules {
   ul {
      list-style-type: disc;
   }
}

.actionsContainer {
   button {
      margin-right: $gs-uitk-space-1;
   }
}

.errorFeedbackLabel {
   padding-left: $gs-uitk-space-1;
   color: $gs-uitk-red-060;
}
