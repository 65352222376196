@import '@gs-ux-uitoolkit-common/core/style/vars';

.layoutContainer {
   height: 100%;

   display: flex;
   flex-direction: column;
   background-color: $gs-uitk-gray-010;

   :global(.uitoolkit-header) {
      :global(.profile-details-container) {
         :global(.profile-img-container) {
            display: none !important;
         }
         :global(.profile-content) {
            margin-left: 0 !important;
         }
      }
   }
}

.workspaceContainer {
   flex: 1;
}

.footerContainer {
   background-color: $gs-uitk-gray-020;
   padding: $gs-uitk-space-0_75 $gs-uitk-space-2;

   .topContainer {
      display: flex;
      justify-content: space-between;
   }

   .versionLabel {
      color: $gs-uitk-gray-050;
   }

   .divider {
      border-bottom: 1px solid $gs-uitk-gray-040;
      margin: $gs-uitk-space-0_75 0;
   }

   p,
   span {
      margin: 0;
      font-size: 12px;
   }
}
